<template>
  <div class='tableTitle'>{{ tableTitle }}</div>
  <div class="flex-top mb-10 mt-10">
    <div class="flex-l">
      <div>
        <template v-for="item in btnOperation">
          <template v-if="item.type === 'selectAll'">
<!--         @click="handleClick(item)"    -->
            <div :key="item.type"  class="buttonAll">
              <a-checkbox
                v-model:checked="checkAll"
                class="checkClass"
                @change="handleChangeByCheckAll"
              ></a-checkbox>
              {{ item.label || '跨页全选' }}
            </div>
          </template>
        </template>
      </div>

    </div>
    <div class="flex-r">
      <search-form
        ref="searchRef"
        :items="items"
        :formWidth="formWidth"
        :default-value="search"
        @fnSearch="handleFnSearch"
      />
      <a-space :size="12" align="baseline">
        <template v-for="item in btnOperation">
          <template v-if="item.upload">
            <a-upload
              :key="item.type"
              :showUploadList="false"
              :headers="{
                tenant: $store.state.account.tenant,
                token: $store.state.account.token,
                code: item.permission,
              }"
              v-bind="item.upload"
              @change="item.fnChange"
            >
              <a-button>
                <template #icon>
                  <component :is="icons[item.icon || 'UploadOutlined']" />
                </template>
                {{ item.label || '上传' }}
              </a-button>
            </a-upload>
          </template>
          <template v-else-if="item.type === 'add'">
            <a-button
              :key="item.type"
              type="primary"
              @click="handleClick(item)"
            >
              <template #icon>
                <component :is="icons[item.icon || 'PlusOutlined']" />
              </template>
              {{ item.label || '新增' }}
            </a-button>
          </template>
          <template v-else-if="item.type === 'delete'">
            <a-button :key="item.type" @click="handleClick(item)">
              {{ item.label || '批量删除' }}
            </a-button>
          </template>
          <template v-else-if="item.type === 'file'">
            <a-button
              :key="item.type"
              :type="item.btnType"
              @click="handleClickFile(item)"
            >
              <template #icon>
                <component :is="icons[item.icon]" />
              </template>
              {{ item.label }}
            </a-button>
            <a-modal
              :title="item.label"
              :key="item.type"
              :visible="visible"
              centered
              @cancel="visible = false"
              @ok="handleClickOk(item)"
            >
              <template v-for="child in item.children">
                <template v-if="child.upload">
                  <a-row
                    :key="child.type"
                    type="flex"
                    align="center"
                    :class="{ 'mb-32': !child.extra }"
                  >
                    <a-col :span="4" class="ta-right"
                      >{{ child.label }}：</a-col
                    >
                    <a-col :span="20">
                      <a-upload
                        :key="child.type"
                        :showUploadList="false"
                        :headers="{
                          tenant: $store.state.account.tenant,
                          token: $store.state.account.token,
                          code: child.permission,
                        }"
                        v-bind="child.upload"
                        @change="child.fnChange"
                      >
                        <a-button>
                          <template #icon>
                            <component
                              :is="icons[child.icon || 'UploadOutlined']"
                            />
                          </template>
                          {{ child.label || '上传' }}
                        </a-button>
                      </a-upload>
                    </a-col>
                  </a-row>
                  <a-row :key="child.type" v-if="child.extra">
                    <a-col :span="4"></a-col>
                    <a-col :span="20" class="iss-extra">
                      <span>{{ child.extra }}</span>
                    </a-col>
                  </a-row>
                </template>
                <template v-else>
                  <a-row
                    :key="child.type"
                    type="flex"
                    align="middle"
                    :class="{ 'mb-32': !child.extra }"
                  >
                    <a-col :span="4" class="ta-right"
                      >{{ child.label }}：</a-col
                    >
                    <a-col :span="20">
                      <a-button
                        :type="child.btnType"
                        @click="handleClick(child)"
                      >
                        <template #icon>
                          <component :is="icons[child.icon]" />
                        </template>
                        {{ child.label }}
                      </a-button>
                    </a-col>
                  </a-row>
                  <a-row :key="child.type" v-if="child.extra">
                    <a-col :span="4"></a-col>
                    <a-col :span="20" class="iss-extra">
                      <span>{{ child.extra }}</span>
                    </a-col>
                  </a-row>
                </template>
              </template>
            </a-modal>
          </template>
          <!--       这个不要显示   -->
          <template v-else-if="item.type === 'selectAll'" class="selectAllNone">
          </template>
          <template v-else>
            <a-button
              :key="item.type"
              :type="item.btnType"
              @click="handleClick(item)"
            >
              <template #icon>
                <component :is="icons[item.icon]" />
              </template>
              {{ item.label }}
            </a-button>
          </template>
        </template>
      </a-space>
    </div>
  </div>
  <!--  <div class="selectAll" v-if="allowSelection && showAllSelect">-->
  <!--    <a-checkbox v-model:checked="checkedAll" @change="selectAllChange">-->
  <!--      <slot name="selectAll" v-bind="pagingConfig"> 全选 </slot>-->
  <!--    </a-checkbox>-->
  <!--  </div>-->
  <a-table
    class="iss-grid"
    rowKey="id"
    size="middle"
    v-bind="$attrs"
    :row-selection="{
      rowSelection,
       onSelect: onSelect,
       onSelectAll: onSelectAll,
       selectedRowKeys: selectedRowKeys,
       onChange: onSelectChange,
       onSelectInvert: onSelectInvert,
    }"
    :data-source="dataSource"
    :pagination="pagingConfig"
    :loading="loading"
  >
    <template v-for="(index, name) in $slots" v-slot:[name]="slotProps">
      <slot :name="name" v-bind="slotProps" />
    </template>
  </a-table>
</template>

<script>
import { createVNode, toRaw } from 'vue';
import {
  message,
  Modal,
  Space,
  Table,
  Upload,
  Row,
  Col,
  Checkbox,
} from 'ant-design-vue';
import * as icons from '@ant-design/icons-vue';
import SearchForm from '@/components/connSearchForm';
import { isDelBoundary } from '@/utils';

export default {
  name: 'Gird',
  components: {
    ASpace: Space,
    ATable: Table,
    AUpload: Upload,
    ARow: Row,
    ACol: Col,
    ACheckbox: Checkbox,
    SearchForm,
  },
  props: {
    code: String,
    formWidth: String,
    url: String,
    tableTitle: String,
    selectRowKey: {
      type: String,
      default: 'id',
    },
    showAllSelect: {
      type: Boolean,
      default: true,
    },
    urlParams: Object,
    allowSelection: [Boolean, Object],
    btnOperation: Array,
    defaultValue: Object,
    items: Array,
    pagination: {
      type: [Object, Boolean],
      default: () => ({}),
    },
  },
  data() {
    return {
      icons,
      loading: false,
      visible: false,
      isSearch: true,
      dataSource: [],
      search: this.defaultValue || {},
      queryParams: this.urlParams || {},
      selectedRowKeys: [],
      selectedRows: [],
      checkAll: false,
      cancelRowsId: [],
      pagingConfig:
        this.pagination === false ? false : this.setPagination(this.pagination),
    };
  },
  created() {
    if (this.url) {
      const { current, pageSize } = this.pagingConfig;
      pageSize ? this.queryListByPaging(current, pageSize) : this.queryList();
    }
  },
  computed: {
    checkedAll() {
      const ids = this.dataSource.map(i => i[this.selectRowKey]);
      if (
        ids?.length > 0 &&
        ids.every(item => this.selectedRowKeys.indexOf(item) > -1)
      ) {
        return true;
      } else {
        return false;
      }
    },
    rowSelection() {
      return this.allowSelection
        ? {
            fixed: 'left',
            selectedRowKeys: this.selectedRowKeys,
            onChange: (selectedRowKeys, selectedRows) => {
              this.selectedRowKeys = selectedRowKeys;
              this.selectedRows = selectedRows;
            },
            ...this.allowSelection,
          }
        : null;
    },
  },
  watch: {
    search: {
      deep: true,
      handler(val) {
        const { pageSize } = this.pagingConfig;
        if (this.isSearch) {
          pageSize ? this.queryListByPaging(1, pageSize) : this.queryList();
        }
        this.isSearch = true;
        this.search = val;
        this.$bus.emit('CONN-SEARCH', { ...toRaw(val) });
      },
    },
    urlParams: {
      handler(val, oldVal) {
        if (JSON.stringify(toRaw(this.queryParams)) !== JSON.stringify(val)) {
          this.queryParams = val;
          const { pageSize } = this.pagingConfig;
          // 单独处理手动处理列表数据
          if (val.identify !== oldVal.identify) {
            this.search = {}
            // 清空搜索框的值
            this.$refs.searchRef.resetFields();
            this.checkAll = false
          } else {
            pageSize ? this.queryListByPaging(1, pageSize) : this.queryList();
          }
        }
      },
    },
    checkAll: {
      // 监听跨页全选框
      // 勾选跨页时，第一页勾选，切换第二页会默认取消了勾选
      handler(val) {
        console.log('跨页全选', val, this.dataSource, this.cancelRowsId)

        if (val) {
          // console.log('选择跨页',  this.checkAll)
          this.checkAll = true

          for (let i = 0; i < this.dataSource.length; i++) {
            // 需要排除调取消勾选的，this.cancelRowsId 包含 this.dataSource[i].id
            if (!this.cancelRowsId.includes(this.dataSource[i].id)) {
              // debugger
              this.selectedRowKeys.push(this.dataSource[i].id);
            }
          }
          // console.log('this.selectedRowKeys', this.selectedRowKeys)

        } else {
          // console.log('取消跨页2',  this.checkAll)
          // this.checkAll = false
          this.selectedRowKeys = [];
          this.cancelRowsId = [];

        }

      }

    },

  },
  methods: {
    onSelect(selectedRowKeys, selectedRows) {
      console.log('onSelect', selectedRowKeys, selectedRows)
      // 	用户手动选择/取消选择某列的回调
      //  selectedRows 是false 的话，需要在 this.selectedRowKeys 里面把这个id 清除掉

      // 把取消勾选的id存起来
      if (!this.cancelRowsId.includes(selectedRowKeys.id)) {
        this.cancelRowsId.push(selectedRowKeys.id)
      }

      // console.log('rowSelection已经选中的数据', this.selectedRowKeys)
      // 渲染出来的数据中排除已经取消的勾选的id
      if (!selectedRows && this.selectedRowKeys.includes(selectedRowKeys.id)) {
        let newSelectedRowKeys = this.selectedRowKeys.filter((item)=> {
          return  item !== selectedRowKeys.id
        })
        this.selectedRowKeys = newSelectedRowKeys
      }

      let result =  this.cancelRowsId.filter(key => !this.selectedRowKeys.includes(key));
      this.cancelRowsId = result
      // console.log('最终定的数据', this.selectedRowKeys, this.cancelRowsId)

    },
    onSelectAll(selected, selectedRows, changeRows) {
      // console.log('onSelectAll6666', selected, selectedRows, changeRows)
      //   点击当前页的取消按钮，需要在 rowSelection 里面批量把这id 清除掉
      if (selected) {

        selectedRows.map((item)=>{
          if (!this.selectedRowKeys.includes(item.id)) {
            this.selectedRowKeys.push(item.id)
          }
        })

      } else {
        //  取消勾选分当页取消勾选和跨页取消勾选
        // 取消单页全选，也判断是否选择了跨页全选， 如果选择了跨页全选，那么需要拿到当前页的id,排除掉，取消勾选

        if (this.checkAll) {
          // 把当前页数据id添加到取消勾选的数组
          this.dataSource.map((item)=>{
            if (!this.cancelRowsId.includes(item.id)) {
              this.cancelRowsId.push(item.id)
            }
          })

          if (selectedRows.length > 0) {
            // 把批量取消勾选的id存起来
            selectedRows.map((item)=> {
              if (!this.cancelRowsId.includes(item.id)) {

                // 这个赋值有问题，把之前勾选上的全部取消勾选，应该只是取消勾选当前页
                // this.cancelRowsId.push(item.id)
                //
                // let result =  this.selectedRowKeys.filter(key => ! this.cancelRowsId.includes(key));
                // this.selectedRowKeys =  result

              } else {

                // this.cancelRowsId.push(item.id)
                let result =  this.selectedRowKeys.filter(key => ! this.cancelRowsId.includes(key));
                // console.log('result 22', result , this.cancelRowsId);
                this.selectedRowKeys =  result
              }

            })
          } else {
            //  处理跨页全选，直接把第一页取消勾选的情况
            let result =  this.selectedRowKeys.filter(key => ! this.cancelRowsId.includes(key));
            this.selectedRowKeys =  result
          }

        } else {
          this.selectedRowKeys = []
        }
      }

    },

    //  selectedRowKeys, selectedRows
    // 勾选当前页的快捷键
    onSelectChange(selectedRowKeys, ) {
      console.log('onSelectChange当前页', selectedRowKeys, )

      selectedRowKeys.map((item)=> {
        // console.log('item当前页 快捷键', item)
        if (!this.selectedRowKeys.includes(item)) {
          this.selectedRowKeys.push(item)
        }
      })

      // 取消的id中包含排除选中的id
      let result = this.cancelRowsId.filter(key => !this.selectedRowKeys.includes(key));
      this.cancelRowsId = result

    },

    selectAllChange(e) {
      console.log(this.selectedRowKeys);
      this.selectedRowKeys = e.target.checked
        ? this.dataSource.map(i => i[this.selectRowKey])
        : [];
    },
    handleFnSearch(val, type) {
      this.search = val;
      this.isSearch = type;
      this.$emit('fnGrid', val);
      this.$emit('searchVal', val);
    },
    setPagination(data) {
      const { onChange, onShowSizeChange } = data;
      return Object.assign(
        {
          showLessItems: true,
          showSizeChanger: true,
          showQuickJumper: true,
          pageSize: 10,
          current: 1,
          size: 'default',
          pageSizeOptions: ['10', '20', '30', '50', '100', '200', '500'],
          showTotal: total => `共 ${total} 条数据`,
        },
        data,
        {
          onChange: (current, pageSize) =>
            this.queryListByPaging(current, pageSize).then(() =>
              onChange?.(current, pageSize)
            ),
          onShowSizeChange: (current, pageSize) =>
            this.queryListByPaging(current, pageSize).then(() =>
              onShowSizeChange?.(current, pageSize)
            ),
        }
      );
    },
    queryListByPaging(current, size) {
      this.loading = true;
      const obj = JSON.parse(JSON.stringify(this.search));
      for (let index = 0; index < this.items.length; index++) {
        if (this.items[index].search === false) {
          delete obj[this.items[index]['key']];
        }
      }
      return this.$http
        .get(this.url, {
          params: { current, size, ...this.queryParams, ...obj },
          code: this.code,
        })
        .then(data => {
          this.$emit('fnGrid', data || data.records);
          this.$emit('fnGridPhone', data || data.records);
          this.dataSource = data.records || data;
          // 开启勾选分页切换下一页时 保留上一页勾选状态
          // this.selectedRowKeys = [];
          // 每次分页请求时，如果checkAll 是true ， 那么需要把当前页数据勾选上，
          // 需要排除掉已经取消勾选的

          if (this.checkAll) {
            this.checkAll = true
            //
            for (let i = 0; i < this.dataSource.length; i++) {
              // 需要排除调取消勾选的，this.cancelRowsId 包含 this.dataSource[i].id
              if (!this.cancelRowsId.includes(this.dataSource[i].id)) {
                this.selectedRowKeys.push(this.dataSource[i].id);
              }
            }

          } else {
            this.checkAll = false;
          }

          this.$emit('dataChange', this.dataSource.length);
          this.pagingConfig = Object.assign({}, this.pagingConfig, {
            current,
            pageSize: size,
            total: parseInt(data.total || data.length),
          });
        })
        .finally(() => (this.loading = false));
    },
    queryList() {
      this.loading = true;
      const obj = JSON.parse(JSON.stringify(this.search));
      for (let index = 0; index < this.items.length; index++) {
        if (this.items[index].search === false) {
          delete obj[this.items[index]['key']];
        }
      }
      return this.$http
        .get(this.url, {
          params: { ...this.queryParams, ...obj },
          code: this.code,
        })
        .then(data => {
          this.dataSource = data.records || data;
          this.selectedRowKeys = [];
          this.$emit('dataChange', this.dataSource.length);
        })
        .finally(() => (this.loading = false));
    },
    refreshGrid(delLength) {
      const { current, pageSize, total,  } = this.pagingConfig;
      let newTotalToStr = ''
      if (this.checkAll) {
        //   跨页全选是true, 总数-取消勾选数 = 列表删除掉的个数
        // let len = total - delLength
        // let newTotal = total - len
        // newTotalToStr = newTotal.toString().charAt(newTotal.toString().length - 1)

        // 只要跨页全选处理后~都返回第一页
        newTotalToStr = '0'

      } else {
        // 列表删除掉的个数 delLength
        let newTotal = total - delLength
        // newTotalToStr 字符串后面的数字是0， 表示这页删除完，回到第一页去
        newTotalToStr = newTotal.toString().charAt(newTotal.toString().length - 1)
      }

      pageSize
        ? this.queryListByPaging(newTotalToStr === '0' ? '1' : current, pageSize)
        : this.queryList();
      this.selectedRowKeys = [];
      this.checkAll = false;
      // pageSize
      //   ? this.queryListByPaging(isCurrent ? current : 1, pageSize)
      //   : this.queryList();
      // this.selectedRowKeys = [];
    },
    isTotal() {
      const { total  } = this.pagingConfig;
      return total
    },

    // 清空筛选的值
    clearSearch() {
      this.search = {}
      // 清空搜索框的值
      this.$refs.searchRef.resetFields();
    },
    selectAll() {
      let parmas = {}
      if (this.checkAll) {
        parmas = {
          selectAll: this.checkAll,
          contactId: this.cancelRowsId,
        }
      } else {
        parmas = {
          selectAll: this.checkAll,
          contactId: this.selectedRowKeys,
        }
      }
      // console.log('parmas', parmas)
       return parmas
    },
    returnCancelRowsId() {
      return this.cancelRowsId
    },
    returnSelectedRowKeys() {
      return this.selectedRowKeys
    },

    handleDelete() {
      let parmas = {}

      if (this.checkAll) {
        parmas = {
          contactId: this.cancelRowsId,
        }
      } else {
        parmas = {
          contactId: this.selectedRowKeys,
        }
      }
      console.log('parmas', parmas)
      return parmas

      // this.$emit('fnGrid', { value: parmas, type: 'extend' });
      // this.checkAll = false;
    },

    getDataSource() {
      let dataSource = this.dataSource;
      return dataSource;
    },
    handleClick(item) {
      const selectedRowKeys = toRaw(this.selectedRowKeys);
      const { total  } = this.pagingConfig;
      let delStu = isDelBoundary(this.checkAll, this.cancelRowsId.length, total, this.selectedRowKeys.length)
      if (!delStu) {
        return message.info('请选择数据');
      }
      // if (!selectedRowKeys.length) {
      //   return message.info('请选择数据');
      // }

      let permission = item.permission;
      typeof permission === 'string' && (permission = [permission]);
      this.$has(...permission).then(() => {
        if (item.type === 'delete') {
          console.log('删除', )
          // const length = selectedRowKeys.length;

          // selectAll 是否跨页全选， cancelRowsIdLength 取消勾选Id 数组长度, total 总数， id 勾选id 数组长度
          // let delStu = isDelBoundary(this.selectAll, this.cancelRowsId.length, total, this.selectedRowKeys.length)
          if (delStu) {
            let sum = null
            if(this.checkAll) {
               sum = total - this.cancelRowsId.length
              // sum = total - len
            } else {
              sum = this.selectedRowKeys.length
            }

            Modal.confirm({
              title: `确认删除选中的 ${sum} 条数据吗？`,
              icon: createVNode(icons['ExclamationCircleOutlined']),
              okType: '',
              okText: '确认',
              onOk: () => item.fnClick(selectedRowKeys),
            });

          } else {
            return message.info('请选择数据');
          }

          // if (length) {
          // } else {
          //   message.info('请先选择数据');
          // }

        } else {
          item.fnClick(selectedRowKeys, toRaw(this.dataSource));
        }
      });
    },
    handleClickFile(item) {
      console.log(item);
      this.visible = true;
    },
    handleClickOk(item) {
      console.log(item);
      this.visible = false;
    },
  },
};
</script>

<style lang="less" scoped>
.flex-l {
  font-size: 16px;
  width: auto;
  padding-right: 20px;
}
.flex-r {
  flex: 1;
  display: flex;
  justify-content: flex-end;
}
.selectAll {
  padding: 10px 21px;
  background: rgba(255, 123, 0, 0.04);
}
.iss-extra {
  height: 32px;
  color: #c9cdd4;
  font-size: 14px;
  span {
    position: absolute;
    top: 2px;
  }
}
.iss-grid {
  border-radius: 8px;
  //overflow-y: auto;
  //box-shadow: 0px 3px 10px 0px @primary-3;
  :deep(.ant-table-header) {
    border-radius: 8px 8px 0 0;
    // 解决表头右侧外边距和底纹问题
    margin-right: -10px;
    //background: #fff5eb;
  }
  :deep(.ant-table.ant-table-middle) {
    .ant-table-content > .ant-table-body > table > .ant-table-tbody > tr > td,
    .ant-table-content
      > .ant-table-scroll
      > .ant-table-body
      > table
      > .ant-table-tbody
      > tr
      > td,
    .ant-table-content
      > .ant-table-fixed-left
      > .ant-table-body-outer
      > .ant-table-body-inner
      > table
      > .ant-table-tbody
      > tr
      > td,
    .ant-table-content
      > .ant-table-fixed-right
      > .ant-table-body-outer
      > .ant-table-body-inner
      > table
      > .ant-table-tbody
      > tr
      > td {
      padding: 7px 16px;
      height: 48px;
    }
    .ant-table-content > .ant-table-header > table > .ant-table-thead > tr > th,
    .ant-table-content > .ant-table-body > table > .ant-table-thead > tr > th,
    .ant-table-content
      > .ant-table-scroll
      > .ant-table-header
      > table
      > .ant-table-thead
      > tr
      > th,
    .ant-table-content
      > .ant-table-scroll
      > .ant-table-body
      > table
      > .ant-table-thead
      > tr
      > th,
    .ant-table-content
      > .ant-table-fixed-left
      > .ant-table-header
      > table
      > .ant-table-thead
      > tr
      > th,
    .ant-table-content
      > .ant-table-fixed-right
      > .ant-table-header
      > table
      > .ant-table-thead
      > tr
      > th,
    .ant-table-content
      > .ant-table-fixed-left
      > .ant-table-body-outer
      > .ant-table-body-inner
      > table
      > .ant-table-thead
      > tr
      > th,
    .ant-table-content
      > .ant-table-fixed-right
      > .ant-table-body-outer
      > .ant-table-body-inner
      > table
      > .ant-table-thead
      > tr
      > th,
    .ant-table-content > .ant-table-header > table > .ant-table-tbody > tr > td,
    .ant-table-content
      > .ant-table-scroll
      > .ant-table-header
      > table
      > .ant-table-tbody
      > tr
      > td,
    .ant-table-fixed-left
      > .ant-table-header
      > table
      > .ant-table-tbody
      > tr
      > td,
    .ant-table-fixed-right
      > .ant-table-header
      > table
      > .ant-table-tbody
      > tr
      > td {
      padding: 12px 16px;
      height: 48px;
    }
  }
  :deep(.ant-btn-icon-only) {
    min-width: 30px;
    width: 30px;
    height: 30px;
  }
  :deep(.ant-table-pagination.ant-pagination) {
    margin: 20px 20px 0px 0;
    font-size: 12px;
    .ant-pagination-options-quick-jumper input,
    .ant-select:not(.ant-select-customize-input) .ant-select-selector {
      background-color: #fff;
    }
    a {
      font-size: 14px;
    }
  }
}
:deep(.ant-table-cell-fix-left) {
  // background: transparent;
}
:deep(
    .ant-table-tbody > tr > td.ant-table-selection-column .ant-radio-wrapper
  ) {
  margin-bottom: 0px;
  margin-top: 7px;
}
:deep(.ant-table-thead > tr > th) {
  background-color: transparent;
}
.buttonAll {
  border: none;
  padding-left: 22px;
}
.tableTitle {
  padding-left: 26px;
  font-weight: 550;
  font-size: 18px;
}
.selectAllNone {
  display: none;
}
.flex-top {
  align-items: center;
}
.checkClass {
  margin-right: 8px;
}

</style>

<template>
  <div class="tips-content">
    <div class="tips">
      <span class="tips-icon">
        <ExclamationCircleFilled />
      </span>
      <span class="tips-text">{{ text }}</span>
    </div>
    <div class="operation-btn">
      <slot name="btnList"></slot>
    </div>
  </div>
</template>

<script>
import { ExclamationCircleFilled } from '@ant-design/icons-vue';

export default {
  name: 'dashboard',
  components: {
    ExclamationCircleFilled,
  },
  props: {
    text: {
      // type: String,
      default: '',
    },
    btnText: {
      type: String,
      default: '返回',
    },
  },
  setup(props, context) {
    const clickHandle = () => context.emit('clickHandle');
    return { clickHandle };
  },
};
</script>

<style lang="less" scoped>
.tips-content {
  width: 100%;
  background: #ffe3c8;
  border-radius: 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px 24px;
}

.tips {
  display: flex;
  align-items: center;
  .tips-icon {
    font-size: 22px;
    padding-right: 8px;
    color: @primary-color;
  }
  .tips-text {
    font-size: 18px;
  }
}
</style>
